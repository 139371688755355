/*
 * @Author: yuhaiyangz@163.com
 * @Date: 2023-03-17 10:03:44
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-17 10:06:29
 * @Description: loading
 */
import './loading.scss'
export default function Loading(){
    return <div className="loadingCom">
            <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            </div>      
        </div>
}