/*
 * @Author: yuhaiyangz@163.com
 * @Date: 2023-01-31 15:58:51
 * @LastEditors: yuhaiyang yuhaiyangz@163.com
 * @LastEditTime: 2024-04-16 09:29:38
 * @Description: 请填写简介
 */
import React,{useState} from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Popup,Collapse,Space,List } from 'antd-mobile'
import './header.scss'
import menu from './img/menu.png'
    interface path {
        readonly title: string,
        readonly url: string,
        list?: path[];
        index?: string;//选中状态
     }
     const headerList: path[] = [//导航
        { title: '首页', url: '/' },
        { title: '指数浏览器', url: '/indexType' },
        {
           title: '我们的客户', url: '/customers/', list: [
              { title: '商业银行', url: '/customers/1' },
              { title: '银行理财', url: '/customers/2' },
              { title: '公募基金', url: '/customers/3' },
              { title: '证劵公司', url: '/customers/4' },
           ]
        },
        {
           title: '解决方案', url: '/solution/', list: [
              { title: '跨资产配置', url: 'solution/crossAsset' },
              { title: '债券指数与估值', url: 'solution/bond' },
              { title: '基金研究与投资', url: 'solution/fof' }
           ]
        },
        { title: '新闻与公告', url: '/news' },
        { title: '研究成果', url: '/research' },
        { title: '合规管理', url: '/compliance' },
        { title: '公司介绍', url: '/company' },
        { title: '加入我们', url: '/join' },
        { title: '联系我们', url: '/contactUs' }
        // {
        //    title: '关于我们', url: '', list: [
        //       { title: '公司介绍', url: '/company', index: '/company' + 0 },
        //       { title: '加入我们', url: '/join', index: '/join' + 1 },
        //       { title: '联系我们', url: '/contactUs', index: '/contactUs' + 2 },
        //       { title: '合规管理', url: '/compliance', index: '/compliance' + 3 },
        //    ]
        // },
     
     ]
export default function CCHeader(){
    const [visible,setVisible] = useState<boolean>(false)
    const navigate = useNavigate()
    const goHome = ()=>{
        navigate('/')
    }
    return(
        <div className='ccxd_header'>
            <img className='logo' src="/img/logo.png" alt="" onClick={()=>goHome()} />
            <img className='menu' src={menu} alt="icon" onClick={()=>setVisible(true)} />
            <Popup
              visible={visible}
              onMaskClick={() => {
                setVisible(false)
              }}
              position='left'
              bodyStyle={{ width: '60vw' }}
              className="ccxd_header"
            >
                <List header='导航'>
                    {
                        headerList.map(item=>{
                            if(!item.list){
                                return <List.Item key={item.url}><NavLink to={item.url} onClick={()=>setVisible(false)}>{item.title}</NavLink></List.Item>
                            }else{
                                return (
                                    <Collapse key={item.url} accordion>
                                        <Collapse.Panel key='1' title={item.title}>
                                            <Space direction='vertical'>
                                                {
                                                    item.list.map(child=>{
                                                        return <NavLink style={{lineHeight:2.5}} key={child.url} to={child.url} onClick={()=>setVisible(false)}>{child.title}</NavLink>
                                                    })
                                                }
                                            </Space>
                                        </Collapse.Panel>
                                    </Collapse>
                                )
                            }
                        })
                    }
                </List>
            </Popup>
        </div>
        
    )
}