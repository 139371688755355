/*
 * @Author: yuhaiyangz@163.com
 * @Date: 2023-03-17 10:26:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-17 10:55:57
 * @Description: 404页面
 */
import { ErrorBlock } from 'antd-mobile'
import './nofound.scss'
import { useLocation } from 'react-router-dom'
import { useEffect,useState } from 'react'
export default function NoFound(){
    const router = useLocation()
    const [pageHeaderHeight, setPageHeaderHeight] = useState<number>(0);
    useEffect(()=>{
        const Header:HTMLElement = document.querySelector('.ccxd_header')!
        const Footer:HTMLElement = document.querySelector('.ccFooter')!
        console.log(Header.offsetHeight);
        console.log(Footer.offsetHeight);
        setPageHeaderHeight(Header.offsetHeight + Footer.offsetHeight)
        
    },[])
    return <div className='noFound' style={{height:`calc( 100vh - ${pageHeaderHeight}px )`}}>
        <ErrorBlock title="页面404" description={
            <>
                <p>当前请求得路由是“<span style={{color
                :'#f00'}}>{router.pathname}</span>”,查看是否正确后重试！</p>
                <p style={{marginTop:'1vw'}}><a href="/">跳转到首页</a></p>            
            </>
        } status='default' />
    </div>
    
}