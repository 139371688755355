/*
 * @Author: yuhaiyangz@163.com
 * @Date: 2023-03-17 09:17:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-17 10:02:38
 * @Description: 路由
 */
import { lazy } from 'react'
interface Router {
    name?: string,
    path: string,
    children?: Array<Router>,
    component: any
  }
const routerList:Router[] = [
    {
        path:'/',
        component:lazy(() => import ('@/pages/home/home'))
    },
    {
        path:'/indexType',
        component:lazy(() => import ('@/pages/indexType/indexType'))
    },
    {
        path:'/indexDetails',
        component:lazy(() => import ('@/pages/details/details'))
    },
    {
        path:'/news',
        component:lazy(() => import ('@/pages/news/news'))
    },
    {
        path:'/research',
        component:lazy(() => import ('@/pages/news/research'))
    },
    {
        path:'/compliance',
        component:lazy(() => import ('@/pages/news/compliance'))
    },
    {
        path:'/news/:id',
        component:lazy(() => import ('@/pages/news/details'))
    },
    {
        path:'/research/:id',
        component:lazy(() => import ('@/pages/news/detailsResearch'))
    },
    {
        path:'/company',
        component:lazy(() => import ('@/pages/company/company'))
    },
    {
        path:'/join',
        component:lazy(() => import ('@/pages/join/join'))
    },
    {
        path:'/contactUs',
        component:lazy(() => import ('@/pages/contactUs/contactUs'))
    },
    {
        path:'/customers/:type',
        component:lazy(() => import ('@/pages/customers/customers'))
    },
    {
        path:'/solution/:type',
        component:lazy(() => import ('@/pages/solution/solution'))
    },
    {
        path:'/solution/details/benchmark/:type',
        component:lazy(() => import ('@/pages/solution/details/benchmark'))
    },
    {
        path:'/solution/details/bondSolutions/:type',
        component:lazy(() => import ('@/pages/solution/details/BondSolutions'))
    },
    {
        path:'/solution/details/indexDerivatives',
        component:lazy(() => import ('@/pages/solution/details/indexDerivatives'))
    },
    {
        path:'/solution/details/indexResearch',
        component:lazy(() => import ('@/pages/solution/details/indexResearch'))
    },
    {
        path:'/solution/details/indexCustom',
        component:lazy(() => import ('@/pages/solution/details/indexCustom'))
    },
    {
        path:'/solution/details/fof/:type',
        component:lazy(() => import ('@/pages/solution/details/fof'))
    },
];
export default routerList